import React from "react";
import Navbar from "../components/Navbar";
import { Hero } from "../components/contactuscomponents/Hero";
import { FooterForm } from "../components/FooterForm";
import { Footer } from "../components/Footer";
import { CeoMessage } from "../components/servicescomponents/CeoMessage";
import seoSec2Image from "../assets/images/services-banner-2nd/clients.webp";
import { ClientLogos } from "../components/homecomponents/ClientLogos";
import stars from "../assets/images/yellow-star.svg";
import client1 from "../assets/images/client-1.webp";
import client2 from "../assets/images/client-2.webp";
import client3 from "../assets/images/client-3.webp";
import client4 from "../assets/images/client-4.webp";
import client5 from "../assets/images/client-5.webp";
import client6 from "../assets/images/client-6.webp";
import client7 from "../assets/images/client-7.webp";
import client8 from "../assets/images/client-8.webp";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { EqualHeight, EqualHeightElement } from "react-equal-height";
export const Clients = () => {
  const obj = [
    {
      clientName: "Amelia",
      clientDesc:
        "We have been particularly happy with the active and professional development team that has offered us valuable insights in setting up our needed site. I would warmly recommend them.",
      clientImage: client1,
    },
    {
      clientName: "Ronald Richards",
      clientDesc:
        "Have worked with Maqsood and team for over 6 years on numerous projects and websites. They always deliver on time and can handle any special requests. They are an important partner in our business and highly recommended!",
      clientImage: client2,
    },
    {
      clientName: "Savannah Nguyen",
      clientDesc:
        "What we appreciated most about working with EWDTech and his team was their ability to cut through the predictable problems of a project, keep everyone happy, and deliver the expected results.",
      clientImage: client3,
    },
    {
      clientName: "Amelia.",
      clientDesc:
        "We have been particularly happy with the active and professional development team that has offered us valuable insights in setting up our needed site. I would warmly recommend them.",
      clientImage: client4,
    },
    {
      clientName: "Michael Richards",
      clientDesc:
        "Whether it’s a new development, update or maintenance – EWDTech always shines through. Their turnaround time is always stellar, it’s a pleasure to work with them.",
      clientImage: client5,
    },
    {
      clientName: "Isabella",
      clientDesc:
        "We were extraordinarily pleased with the functionality and depth of understanding that EWDTech solution exhibited after a relatively brief but incisive, project kickoff meeting.",
      clientImage: client6,
    },
    {
      clientName: "Floyd Miles",
      clientDesc:
        "EWDTech has developed our award winning cross platform app on the Deep Touch framework and we are very happy with the implementation and the capabilities of the product.",
      clientImage: client7,
    },
    {
      clientName: "David Rock",
      clientDesc:
        "We work together for several years since the very early days of our company. We have been able to offload a significant portion of our development effort to their team. They have been reliable and responsive to our needs.",
      clientImage: client8,
    },
  ];
  const settings = {
    infinite: true,
    lazyLoad: true,
    speed: 1000,
    slidesToShow: 3,
    arrows: false,
    autoplaySpeed: 3000,
    autoplay: true,
    slidesToScroll: 3,
    dots: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
    centerPadding: 0,
    // nextArrow: <img src={right} />,
    // prevArrow: <img src={right} />,
    // beforeChange: (current, next) => setImageIndex (next),
  };
  return (
    <React.Fragment>
      <Navbar />
      <Hero
        pageName="Our Honourable Clients"
        title="Our Clint's Gets Quality work in all over World"
        desc="On behalf of our management and employees are very proud and happy to announce that our teams have delivered number of projects successfully with total ownership in consulting positions to top financial clients."
      />
      <CeoMessage
        title="Marketing Must 
        Yield Results"
        hidden="hidden"
        buttonText="Get Free Proposal"
        image={seoSec2Image}
        desc={`As a digital lead generation agency, we specialize in B2C & B2B lead generation, inbound marketing, conversion optimization, and sales nurturing. Our process is data-driven focusing on metrics that boost your business’ growth.
        Are you feeling fed up with using digital agencies that don’t bring business? Our metrics for performance are different. We believe in authenticity and effective lead generation through quality link-building, email marketing, search engine optimization, and social media marketing.`}
      />
      <ClientLogos
        title="Our Clients"
        desc="Our data-driven approach excites us to help our clients thrive by evergreen support"
        bgColor="bg-1e1e1e"
      />

      <section className="py-16 bg-efefef maindPadding">
        <h4 className="text-3xl bold-font text-center">
          Don’t take our word for it. Trust our customers
        </h4>
        <section className="pt-12 lg:mb-12 client-carousel">
          <EqualHeight>
            <Slider {...settings} loop={true} autoplay={true}>
              {obj.map((e) => {
                return (
                  <div className="bg-white lg:p-8 sm:p-6 p-6 rounded-2xl">
                    <div className="flex justify-between items-start">
                      <div>
                        <img src={e.clientImage} alt="" srcSet="" />
                      </div>
                      <div className="flex">
                        <img alt="..." src={stars} className="pr-1" />
                        <img alt="..." src={stars} className="pr-1" />
                        <img alt="..." src={stars} className="pr-1" />
                        <img alt="..." src={stars} className="pr-1" />
                        <img alt="..." src={stars} className="pr-1" />
                      </div>
                    </div>
                    <EqualHeightElement>
                      <h4 className="text-xl text-133240 bold-font pt-3">
                        {e.clientName}
                      </h4>
                      <p className="text-133240 pt-3">{e.clientDesc}</p>
                    </EqualHeightElement>
                  </div>
                );
              })}
            </Slider>
          </EqualHeight>
        </section>
      </section>
      <FooterForm desc="Lead Generation Marketing is like playing the chess, where the Queen rules making knights grumble with her tact. Let us revolutionize you and build strategic Lead Generation." />
      <Footer />
    </React.Fragment>
  );
};
