import React from 'react';
import team1 from '../../assets/images/team/waseem.webp';
import team2 from '../../assets/images/team/sarfaraz.webp';
import team3 from '../../assets/images/team/asad.webp';
import team4 from '../../assets/images/team/noman-deisgner.webp';
import team5 from '../../assets/images/team/numan.webp';
import team6 from '../../assets/images/team/muneeb.webp';
import team7 from '../../assets/images/team/hammad.webp';
import team8 from '../../assets/images/team/tahir.webp';
export const Leadership = () => {
  const arrTeam = [
    {
      id: 1,
      image: team1,
      title: 'Muhammad Waseem',
      desc: 'PHP/Laravel Developer',
    },
    {
      id: 2,
      image: team2,
      title: 'Muhammad Sarfraz',
      desc: 'PHP/Laravel Developer',
    },
    {
      id: 3,
      image: team3,
      title: 'M. Asad Ullah',
      desc: 'Frontend Developer',
    },
    {
      id: 4,
      image: team4,
      title: 'Numan Ali',
      desc: 'Graphic Designer',
    },
    {
      id: 5,
      image: team5,
      title: 'Noman Ali Aslam',
      desc: 'Marketer',
    },
    {
      id: 6,
      image: team6,
      title: 'Muneeb Altaf',
      desc: 'Expert Researcher ',
    },
    {
      id: 7,
      image: team7,
      title: 'M. Hammad Ahmad ',
      desc: 'Business Developement Manager',
    },
    {
      id: 8,
      image: team8,
      title: 'Muhammad Tahir Ali',
      desc: 'Angular JS Developer ',
    },
  ];
  return (
    <React.Fragment>
      <section className="mainMarginTop maindPadding">
        <h1 className="text-4xl bold-font  font-black">
          Leadership
        </h1>
        <p className="md:w-4/12 leading-6 opacity-70 w-full sm:w-full pt-5">
        We serve with dignity, shoot for the moon and dive to explore that makes us shining Experts transforming industries.
        </p>
        <div className="flex flex-wrap pt-6 justify-between">
          {arrTeam.map (e => {
            return (
              <div key={e.id} className="team-col mb-8">
              <figure className='photo'>
              <img src={e.image} alt="..."  className="w-full filter_img" />
              </figure>
                <h3 className="text-2xl bold-font  font-black pt-4">
                  {e.title}
                </h3>
                {/* <p className="opacity-70 pt-2">{e.desc}</p> */}
              </div>
            );
          })}

        </div>
      </section>
    </React.Fragment>
  );
};
