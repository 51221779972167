import React from "react";
import { Footer } from "../components/Footer";
import { Hero } from "../components/contactuscomponents/Hero";
import { Form } from "../components/contactuscomponents/Form";
export const Contactus = () => {
  return (
    <React.Fragment>
      <Hero
        pageName="Contact Us"
        title="Let us know About your Business."
        desc="Working with us means Thinking Like A Challenger. No matter what size your company is, it means getting creative, scrappy, and flexible in UX Designs, Creative Writing, Search Engine Optimization, Ranking, Development and much more."
      />
      <Form />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </React.Fragment>
  );
};
