import React from 'react';
import Lightroom from 'react-lightbox-gallery';
import g2 from '../../assets/images/our-work/E-Commerce/2.webp';
import g3 from '../../assets/images/our-work/E-Commerce/3.webp';
import g4 from '../../assets/images/our-work/E-Commerce/4.webp';
import g5 from '../../assets/images/our-work/E-Commerce/5.webp';
import g6 from '../../assets/images/our-work/E-Commerce/6.webp';
export const Ecommerce = () => {
  var images = [
    {
      src: g2,
      desc: ' ',
      sub: '',
    },
    {
      src: g3,
      desc: ' ',
      sub: '',
    },
    {
      src: g3,
      desc: ' ',
      sub: '',
    },
    {
      src: g4,
      desc: ' ',
      sub: '',
    },
    {
      src: g5,
      desc: ' ',
      sub: '',
    },
    {
      src: g6,
      desc: ' ',
      sub: '',
    },
  ];

  var settings = {
    columnCount: {
      default: 3,
      mobile: 2,
      tab: 4,
    },
    mode: 'dark',
  };
  return (
    <React.Fragment>
      <section className=" bg-center bg-no-repeat  bg-cover  pb-12">

        {/* <div className="text-4xl portfolioImageInner sticky inset-x-0 top-0 left-0   bold-font  text-center m-auto text-center w-full">
          Portfolio
        </div> */}
        <div className="relative">
            <Lightroom images={images} settings={settings} />
        </div>
      </section>
    </React.Fragment>
  );
};
