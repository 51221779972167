import React from 'react';
import Navbar from '../components/Navbar';
import { FooterForm } from '../components/FooterForm';
import { Footer } from '../components/Footer';
import { Whoweare } from '../components/aboutuscomponents/Whoweare';
import { CeoMessage } from '../components/aboutuscomponents/CeoMessage';
import { KeepFocus } from '../components/aboutuscomponents/KeepFocus';
import { Leadership } from '../components/aboutuscomponents/Leadership';
import { Jointeam } from '../components/aboutuscomponents/Jointeam';
import { Portfolio } from '../components/Portfolio';
import { Hero } from '../components/contactuscomponents/Hero';
export const About = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Hero
        classBg="about-image"
        pageName="About EDWTECH"
        title="We are intimating your business with a digitized outlook."
        hiddenDesc="hidden"
      />
      <Whoweare />
      <CeoMessage />
      <KeepFocus />
      <Leadership />
      <Jointeam />
      <Portfolio />
      <FooterForm desc="Let’s come and share the table to uncover prospective solutions that can mount economic progress in no time. " />
      <Footer />
    </React.Fragment>
  );
};
