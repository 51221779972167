import React from "react";
import Navbar from "../components/Navbar";
import { Hero } from "../components/contactuscomponents/Hero";
import {PpcServices} from "../components/ppccomponents/PpcServices";
import { GetInspired } from "../components/homecomponents/GetInspired";
import { Portfolio } from "../components/Portfolio";
import { FooterForm } from "../components/FooterForm";
import { Footer } from "../components/Footer";
import { CeoMessage } from "../components/servicescomponents/CeoMessage";
import { Lead } from "../components/homecomponents/Lead";
import seoSec2Image from "../assets/images/services-banner-2nd/PPC-100.webp";
import { LimitedBudget } from "../components/ppccomponents/LimitedBudget";
import { Optimizationproced } from "../components/ppccomponents/Optimizationproced";
export const PpcManagement = () => {
  return (
    <React.Fragment>
      <Navbar />
      <Hero
        pageName="PPC MANAGEMENT"
        title="Build Campaigns To Fit 
        Your Google Ads Budget"
        hiddenDesc="hidden"
      />
      <CeoMessage
        title="Tailor Your PPC Strategy According To Your Business’s Goals"
        hidden="hidden"
        buttonText="Get Free Proposal"
        image={seoSec2Image}
        desc={
          <>
            89% of marketers say brand awareness is the most important goal,
            followed by sales and lead generation. Our PPC Management Service
            enables you to:
            <ul className="pl-4 pt-3">
              <li className="list-disc">Increase Brand Awareness</li>
              <li className="list-disc">Drive Sales</li>
              <li className="list-disc">Generate Leads</li>
              <li className="list-disc">Create Impact</li>
            </ul>
          </>
        }
      />
      <Lead />
      <PpcServices />
      <Optimizationproced />
      <LimitedBudget />
      <GetInspired desc="We devise strategies to enhance possibilities and embrace limitless opportunities. Here are some of our impact stories." />
      <Portfolio />
      <FooterForm desc="We shall like to bid effective PPC Advertising Strategy for you and boost your growth with swift mode of action. Get in Touch for an insightful conversation." />
      <Footer />
    </React.Fragment>
  );
};
