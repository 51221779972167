import React from 'react';
import Lightroom from 'react-lightbox-gallery';
import g1 from '../../assets/images/our-work/funnels/1.webp';
import g2 from '../../assets/images/our-work/funnels/2.webp';
import g3 from '../../assets/images/our-work/funnels/3.webp';
import g4 from '../../assets/images/our-work/funnels/4.webp';
import g5 from '../../assets/images/our-work/funnels/5.webp';
import g6 from '../../assets/images/our-work/funnels/6.webp';
import g7 from '../../assets/images/our-work/funnels/7.webp';
export const Funnels = () => {
  var images = [
    {
      src: g1,
      desc: '',
      sub: '',
    },
    {
      src: g2,
      desc: ' ',
      sub: '',
    },
    {
      src: g3,
      desc: ' ',
      sub: '',
    },
    {
      src: g4,
      desc: ' ',
      sub: '',
    },
    {
      src: g5,
      desc: ' ',
      sub: '',
    },
    {
      src: g6,
      desc: ' ',
      sub: '',
    },
    {
      src: g7,
      desc: ' ',
      sub: '',
    },
  ];

  var settings = {
    columnCount: {
      default: 3,
      mobile: 2,
      tab: 4,
    },
    mode: 'dark',
  };
  return (
    <React.Fragment>
      <section className=" bg-center bg-no-repeat  bg-cover  pb-12">
        <div className="relative">
          <div className="flex ">
            <Lightroom images={images} settings={settings} />
          </div>
        </div>
      </section>

    </React.Fragment>
  );
};
